import { IBlockIFrame } from 'types/ContentfulTypes';
import { cn } from 'utils/cn';

interface ContentfulHTMLComponentProps {
  className?: string;
  content: IBlockIFrame;
}

const ContentfulHTMLComponent = ({ className, content }: ContentfulHTMLComponentProps) => {
  const { content: rawHTMLContent } = content.fields;
  return (
    <div
      dangerouslySetInnerHTML={{ __html: rawHTMLContent }}
      className={cn('[&_a]:text-blue-600 [&_a]:underline', className)}
      id={content.sys?.id}
    />
  );
};

export default ContentfulHTMLComponent;
